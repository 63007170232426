<!--
 * @Author: your name
 * @Date: 2020-11-24 16:15:45
 * @LastEditTime: 2021-02-05 17:27:14
 * @LastEditors: yangliao
 * @Description: 认证页面
 * @FilePath: /netHallOfficialAccounts/src/views/CertifiedPage/index.vue
-->
<template>
  <div class="certifiedPage">
    <!-- <div class="certified-steps">
      <div class="step">
        <em>1</em>
        <p>短信验证</p>
      </div>
      <div class="step">
        <em class="lastStepEm">2</em>
        <p class="lastStep">证件验证</p>
      </div>
      <div class="step">
        <em class="lastStepEm">3</em>
        <p class="lastStep">人脸识别</p>
      </div>
    </div> -->
    <div class="certified-content">
      <van-form @submit="onSubmit">
        <van-field
          label-width="80px"
          v-model="dataInfo.fullName"
          label="姓名"
          placeholder="请填写姓名"
          clearable
          @input="getCheckDisabled"
          :rules="rules.fullName"
          v-if="certifyType == '2' || certifyType == '3'"
        />
        <van-field
          label-width="80px"
          v-model="dataInfo.identityTypeName"
          name="identityType"
          label="证件类型"
          placeholder="请选择证件类型"
          :readonly="identityTypes.length <= 0"
          is-link
          @click="identityTypes.length > 0 ? (showIdentityType = true) : (showIdentityType = false)"
          v-if="certifyType == '2' || certifyType == '3'"
        >
        </van-field>
        <van-action-sheet
          v-model="showIdentityType"
          :actions="identityTypes"
          @select="onIdentityTypeSelect"
        />
        <van-field
          label-width="80px"
          v-model="dataInfo.identityId"
          label="证件号码"
          placeholder="请填写证件号码"
          clearable
          @input="getCheckDisabled"
          :rules="
            dataInfo.identityType === 'CRED_PSN_CH_IDCARD'
              ? rules.identityId
              : dataInfo.identityType === 'CRED_PSN_PASSPORT'
              ? rules.passport
              : rules.gatePassCode
          "
          v-if="certifyType == '2' || certifyType == '3'"
        />
        <van-field
          label-width="80px"
          v-model="dataInfo.phoneNum"
          label="手机号"
          placeholder="请填写手机号"
          clearable
          type="number"
          maxlength="11"
          @input="getCheckDisabled"
          :rules="rules.phoneNum"
          v-if="certifyType == '1' || certifyType == '3'"
        />
        <van-field
          label-width="80px"
          label="验证码"
          placeholder="填写验证码"
          clearable
          center
          type="number"
          maxlength="6"
          :rules="rules.verificationCode"
          @input="getCheckDisabled"
          v-model="dataInfo.verificationCode"
          v-if="certifyType == '1' || certifyType == '3'"
        >
          <template #button>
            <van-button
              size="mini"
              type="default"
              class="sms-button"
              native-type="button"
              @click="getCode"
              :disabled="isDisabled"
            >
              {{ buttonContent }}
            </van-button>
          </template>
        </van-field>
        <div class="submit-button">
          <van-button round block type="info" native-type="submit" :disabled="isDisabledSubmit">
            确定
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { getCode, certifyByPhone, getCardTypes, certifyAll } from '@/api/home';
import { isMobile, identityId, isTellCode, gatePassCode, passport } from '@/utils/validator';
import { Toast } from 'vant';
export default {
  name: 'CertifiedPage',
  data() {
    return {
      dataInfo: {
        fullName: '',
        identityType: '',
        identityTypeName: '',
        identityId: '',
        phoneNum: '',
        verificationCode: '',
      },
      rules: {
        fullName: [{ required: true, message: '姓名不能为空' }],
        identityId: [{ validator: identityId, message: '身份证输入错误' }],
        gatePassCode: [{ validator: gatePassCode, message: '通行证输入错误' }],
        passport: [{ validator: passport, message: '护照输入错误' }],
        phoneNum: [{ validator: isMobile, message: '手机号输入错误' }],
        verificationCode: [{ validator: isTellCode, message: '请输入正确的验证码' }],
      },
      wait: 60,
      buttonContent: '获取验证码',
      isDisabled: false, // 验证码按钮
      isDisabledSubmit: true, // 提交按钮
      show: false,
      identityTypes: [],
      showIdentityType: false,
    };
  },
  computed: {
    certifyType() {
      return this.$store.state.user.homeInfo.certifyType;
    },
  },
  mounted() {
    if (this.certifyType === '2') {
      this.dataInfo.verificationCode = '123456';
    }
    this.searchCardTypes();
  },
  methods: {
    onIdentityTypeSelect(value) {
      this.dataInfo.identityTypeName = value.name;
      this.dataInfo.identityType = value.code;
      this.showIdentityType = false;
      this.getCheckDisabled();
    },
    async searchCardTypes() {
      try {
        let res = await getCardTypes();
        if (res.status === 'complete') {
          this.identityTypes = [...res.resultData];
        } else {
          Toast('查询证件类型错误');
        }
      } catch (error) {
        Toast('查询证件类型错误');
      }
    },
    // 获取验证码
    async getCode() {
      if (isMobile(this.dataInfo.phoneNum)) {
        this.getSendMessage();
        let arg = {
          phone: this.dataInfo.phoneNum,
        };
        const { status, resultData } = await getCode(arg);
        if (status === 'complete') {
          Toast({ icon: 'passed', message: '发送成功！' });
        } else {
          Toast({ icon: 'close', message: '发送失败！' });
        }
      } else {
        return Toast('请填写正确的手机号');
      }
    },
    // 验证码倒计时
    getSendMessage() {
      if (this.wait === 0) {
        this.isDisabled = false;
        this.buttonContent = '重新获取验证码';
        this.wait = 60;
      } else {
        this.isDisabled = true;
        this.buttonContent = this.wait + 's后重新获取';
        this.wait--;
        setTimeout(() => {
          this.getSendMessage();
        }, 1000);
      }
    },
    // 提交
    async onSubmit(values) {
      let arg = {
        code: this.dataInfo.verificationCode === '' ? undefined : this.dataInfo.verificationCode,
        idNo: this.dataInfo.identityId === '' ? undefined : this.dataInfo.identityId,
        phone: this.dataInfo.phoneNum === '' ? undefined : this.dataInfo.phoneNum,
        realName: this.dataInfo.fullName === '' ? undefined : this.dataInfo.fullName,
        identityType: this.dataInfo.identityType === '' ? undefined : this.dataInfo.identityType,
      };
      const { status, resultData } = await certifyAll(arg);
      if (status === 'complete') {
        if (this.certifyType === '1') {
          this.$router.push({ path: '/' });
        } else {
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = resultData;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        Toast('提交信息错误');
      }
    },
    // 下一步是否置灰
    getCheckDisabled() {
      const { fullName, identityId, phoneNum, verificationCode, identityType } = this.dataInfo;
      let flag = false;
      if (this.certifyType == '1') {
        if (phoneNum && verificationCode) {
          flag = false;
        } else {
          flag = true;
        }
      } else if (this.certifyType == '2') {
        if (fullName && identityId && identityType) {
          flag = false;
        } else {
          flag = true;
        }
      } else if (this.certifyType == '3') {
        if (fullName && identityId && phoneNum && verificationCode && identityType) {
          flag = false;
        } else {
          flag = true;
        }
      }
      this.isDisabledSubmit = flag;
      // if (fullName && identityId && phoneNum && verificationCode && identityType) {
      //   this.isDisabledSubmit = false;
      // } else {
      //   this.isDisabledSubmit = true;
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.certifiedPage {
  background: #ffffff;
  // .certified-steps {
  //   padding-top: 40px;
  //   padding-bottom: 30px;
  //   .step {
  //     display: inline-block;
  //     text-align: center;
  //     width: calc(100% / 3);
  //     position: relative;
  //     em {
  //       width: 30px;
  //       height: 30px;
  //       line-height: 30px;
  //       background: #5f97f4;
  //       color: #ffffff;
  //       font-style: normal;
  //       display: inline-block;
  //       border-radius: 100px;
  //       text-align: center;
  //     }
  //     p {
  //       margin: 0px;
  //       padding-top: 10px;
  //       color: #666666;
  //       font-size: 12px;
  //       color: #5f97f4;
  //     }
  //     .lastStepEm {
  //       background: #cccccc;
  //     }
  //     .lastStep {
  //       color: #cccccc;
  //     }
  //   }
  //   .step::after {
  //     display: inline-block;
  //     content: '';
  //     width: 20px;
  //     height: 1px;
  //     background: #cccccc;
  //     position: absolute;
  //     right: 0px;
  //     top: 20%;
  //   }
  //   .step:last-child::after {
  //     display: inline-block;
  //     content: '';
  //     visibility: hidden;
  //   }
  // }
  .certified-content {
    padding-top: 30px;
    /deep/.van-cell {
      font-size: 16px;
    }
    .sms-button {
      color: #999999;
      border: 1px solid #999999;
      border-radius: 100px;
      color: #4466ad;
      border: 1px solid #4466ad;
      .van-button__text {
        font-size: 12px;
      }
    }
    .submit-button {
      border-radius: 5px;
      margin: 100px 16px 0px;
      padding-bottom: 100px;
      p {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #999999;
        text-align: center;
        line-height: 17px;
        margin-top: 12px;
        em {
          font-style: normal;
          color: #5f97f4;
        }
      }
      .van-button {
        font-size: 16px;
        border-radius: 8px;
      }
      /deep/.van-button--mini {
        padding: 0 9px;
      }
    }
  }
  .popupContent {
    p {
      text-align: center;
      color: #666;
    }
    .list {
      ul {
        text-align: center;
        margin: 0px 16px;
        li {
          height: 35px;
          line-height: 35px;
          color: #666666;
          font-size: 14px;
          border-bottom: 1px solid #cccccc;
        }
        li:last-child {
          border-bottom: 0px solid transparent;
        }
      }
    }
  }
}
</style>
